<template>
  <div>
    <v-row class="mx-1">
      <v-col cols="12">
        <v-card id="card-custom" class="px-2 py-4 rounded-lg">
          <v-card-actions>
            <p class="headline-color px-2">Penjadwalan Libur</p>
          </v-card-actions>
          <v-divider />
          <v-row class="mx-2">
            <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12" class="px-0">
              <v-card-actions>
                <v-btn
                  text
                  class="text-capitalize caption headline-color"
                  color="primary"
                  @click="
                    visible = true;
                    form.id = null;
                  "
                  >Buat Penjadwalan Libur<v-icon class="ml-2" color="primary"
                    >add_circle</v-icon
                  ></v-btn
                >
              </v-card-actions>
            </v-col>
            <v-col cols="12" xl="2" lg="2" md="2" sm="12" xs="12" class="px-0">
              <v-select
                v-model="year"
                :items="years"
                placeholder="- Seluruh Tahun -"
                outlined
                dense
                clearable
                hide-details
              ></v-select>
            </v-col>
            <v-col cols="12" xl="2" lg="2" md="2" sm="12" xs="12" class="px-0">
              <v-autocomplete
                placeholder="- Seluruh Unit Utama - "
                v-model="unit"
                :items="units"
                :loading="loadingUnit"
                outlined
                dense
                hide-details
                clearable
                item-text="singkatan"
                item-value="unit_utama_id"
                class="mx-2"
                @change="changeUnit(1)"
                @click:clear="changeUnit(1)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" xl="2" lg="2" md="2" sm="12" xs="12" class="px-0">
              <v-autocomplete
                placeholder="- Seluruh Unit Kerja - "
                v-model="unit2"
                :items="units2"
                :loading="loadingUnit"
                outlined
                dense
                hide-details
                clearable
                item-text="singkatan"
                item-value="unit_kerja_2_id"
                class="mx-2"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" xl="2" lg="2" md="2" sm="12" xs="12" class="px-0">
              <v-text-field
                v-model="search"
                placeholder="Cari"
                outlined
                dense
                hide-details
                prepend-inner-icon="search"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-data-table
            id="table-custom"
            :headers="headers"
            :items="holidays"
            :loading="loading"
            :options.sync="options"
            :server-items-length="pagination.totalItem"
            :footer-props="{
              'items-per-page-options': rowsPerPageItems
            }"
            loading-text="Loading... Please wait"
          >
            <template v-slot:[`item.tanggal_libur`]="{ item }">
              <span>{{ item.tanggal_libur | date }}</span>
            </template>
            <template v-slot:[`item.unit_utama_id`]="{ item }">
              <span>{{
                item.unit_utama_id == null
                  ? "Semua Unit Utama"
                  : item.unit_utama_id
              }}</span>
            </template>
            <template v-slot:[`item.unit_kerja_2_id`]="{ item }">
              <span>{{
                item.unit_kerja_2_id == null
                  ? "Semua Unit Kerja"
                  : item.unit_kerja_2.singkatan
              }}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                x-small
                color="#297BBF"
                class="mr-2 my-1 white--text"
                @click="handleEdit(item)"
                >Edit</v-btn
              >
              <v-btn
                v-if="item.btn_hapus"
                x-small
                color="#FBB005"
                class="mr-2 my-1 white--text"
                @click="handleDelete(item)"
                >Hapus</v-btn
              >
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="visible" persistent max-width="400">
      <v-card class="pa-5 rounded-lg" id="card-custom">
        <v-btn @click="visible = false" x-small icon class="float-right">
          <v-icon>close</v-icon>
        </v-btn>
        <v-card-text class="subtitle-2 px-0 font-weight-regular">
          Buat Hari Libur
        </v-card-text>
        <v-divider class="mb-4" />
        <v-form
          id="form"
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="save()"
        >
          <v-row>
            <v-col cols="5" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Nama Hari Libur</v-subheader
              >
            </v-col>
            <v-col cols="7" class="py-1">
              <v-text-field
                v-model="form.hari_libur"
                outlined
                dense
                :rules="[v => !!v || 'Nama Hari Libur harus diisi']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="form.id">
            <v-col cols="5" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Tanggal Libur</v-subheader
              >
            </v-col>
            <v-col cols="7" class="py-1">
              <v-text-field
                v-model="form.tanggal_libur"
                type="date"
                outlined
                dense
                :rules="[v => !!v || 'Tanggal Libur harus diisi']"
              ></v-text-field>
            </v-col>
          </v-row>
          <template v-else>
            <v-row>
              <v-col cols="5" class="py-1">
                <v-subheader class="caption headline-color font-weight-regular"
                  >Tanggal Mulai</v-subheader
                >
              </v-col>
              <v-col cols="7" class="py-1">
                <v-text-field
                  v-model="form.tanggal_mulai_libur"
                  type="date"
                  outlined
                  dense
                  :rules="[v => !!v || 'Tanggal Mulai harus diisi']"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5" class="py-1">
                <v-subheader class="caption headline-color font-weight-regular"
                  >Tanggal Akhir</v-subheader
                >
              </v-col>
              <v-col cols="7" class="py-1">
                <v-text-field
                  v-model="form.tanggal_akhir_libur"
                  type="date"
                  outlined
                  dense
                  :rules="[v => !!v || 'Tanggal Akhir harus diisi']"
                ></v-text-field>
              </v-col>
            </v-row>
          </template>
          <v-row>
            <v-col cols="5" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Unit Utama</v-subheader
              >
            </v-col>
            <v-col cols="7" class="py-1">
              <v-select
                placeholder="- Semua Unit Utama -"
                v-model="form.unit_utama_id"
                :items="units"
                :loading="loadingUnit"
                outlined
                dense
                clearable
                item-text="singkatan"
                item-value="unit_utama_id"
                @change="changeUnitForm(1)"
                @click:clear="changeUnitForm(1)"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Unit Kerja</v-subheader
              >
            </v-col>
            <v-col cols="7" class="py-1">
              <v-select
                placeholder="- Semua Unit Kerja -"
                v-model="form.unit_kerja_2_id"
                :items="units2Form"
                :loading="loadingUnit"
                outlined
                dense
                clearable
                item-text="singkatan"
                item-value="unit_kerja_2_id"
              ></v-select>
            </v-col>
          </v-row>
          <v-card-actions class="mt-3">
            <v-spacer />
            <v-btn
              small
              outlined
              color="#717171"
              class="px-12"
              @click="visible = false"
              >Batal</v-btn
            >
            <v-btn
              small
              color="#FBB005"
              class="px-12 subtitle-2 text-capitalize"
              :loading="formLoading"
              type="submit"
              :disabled="!valid"
              form="form"
              >Simpan</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import DataUtamaService from "@/services/resources/data.utama.service";
import HolidayService from "@/services/resources/holiday.service";

export default {
  data() {
    return {
      visible: false,
      valid: false,
      loading: false,
      loadingUnit: false,
      formLoading: false,
      deleteLoading: false,
      years: this.generateArrayOfYears(),
      units: [],
      units2: [],
      units2Form: [],
      search: null,
      unit: null,
      unit2: null,
      year: null,
      headers: [
        {
          text: "Tanggal",
          value: "tanggal_libur",
          sortable: false,
          align: "center"
        },
        {
          text: "Hari Libur",
          value: "hari_libur",
          sortable: false,
          align: "center"
        },
        {
          text: "Unit Utama",
          value: "unit_utama_id",
          sortable: false,
          align: "center"
        },
        {
          text: "Unit Kerja",
          value: "unit_kerja_2_id",
          sortable: false,
          align: "center"
        },
        { text: "", value: "actions", sortable: false, align: "center" }
      ],
      holidays: [],
      pagination: {
        page: 1,
        itemsPerPage: 10,
        totalItem: 0,
        pageCount: 0
      },
      options: {
        sortBy: ["holiday_date"],
        sortDesc: [false]
      },
      dayDiffTry: 0,
      dayDiff: 0,
      rowsPerPageItems: [10, 20, 30, 40],
      form: {
        id: null,
        hari_libur: null,
        tanggal_libur: null,
        tanggal_mulai_libur: null,
        tanggal_akhir_libur: null,
        unit_utama_id: null,
        unit_kerja_2_id: null
      }
    };
  },
  watch: {
    options: {
      handler() {
        this.fetchListDebounce(this.getListHoliday);
      },
      deep: true
    },
    paginationProperty: function() {
      this.options.page = 1;
      this.fetchListDebounce(this.getListHoliday);
    },
    visible(val) {
      if (!val) {
        this.dayDiff = 0;
        this.dayDiffTry = 0;
        this.$refs.form.reset();
      }
    }
  },
  computed: {
    paginationProperty() {
      return [this.search, this.year, this.unit, this.unit2].join();
    }
  },
  methods: {
    changeUnit(type = 1) {
      if (type == 1) {
        this.units2 = this.units.find(
          d => d.unit_kerja_2_id == this.unit2
        )?.unit_kerja2;
        this.unit2 = null;
      }
    },
    changeUnitForm(type = 1) {
      if (type == 1) {
        this.units2Form = this.units.find(
          d => d.unit_utama_id == this.form.unit_utama_id
        )?.unit_kerja2;
        this.form.unit_kerja_2_id = null;
      }
    },
    populateUnit() {
      this.units2Form = this.units.find(
        d => d.unit_utama_id == this.form.unit_utama_id
      )?.unit_kerja2;
    },
    generateArrayOfYears() {
      var max = new Date().getFullYear() + 1;
      var min = max - 4;
      var years = [];

      for (var i = max; i >= min; i--) {
        years.push(i);
      }
      return years;
    },
    handleEdit(item) {
      this.visible = true;
      this.getDetailHoliday(item.id_libur);
    },
    handleDelete(item) {
      this.$confirm({
        title: "Konfirmasi",
        message: `Apakah anda yakin akan menghapus penjadwalan libur ini?`,
        button: {
          no: "Batal",
          yes: "Ya"
        },
        callback: confirm => {
          if (confirm) {
            this.deleteHoliday({
              id_libur: item.id_libur
            });
          }
        }
      });
    },
    save() {
      if (this.$refs.form.validate()) {
        var a = this.$moment(this.form.tanggal_mulai_libur);
        var b = this.$moment(this.form.tanggal_akhir_libur);
        let dayDiff = b.diff(a, "days");
        if (dayDiff < 0) {
          this.$store.commit("snackbar/setSnack", {
            show: true,
            message: "Masukan tanggal mulai lebih dahulu dari tanggal akhir",
            color: "error"
          });
        } else if (dayDiff >= 30) {
          this.$store.commit("snackbar/setSnack", {
            show: true,
            message: "Masukan jarak tanggal kurang dari 30 hari",
            color: "error"
          });
        } else {
          let payload = {
            action: this.form.id ? 2 : 1,
            id_libur: this.form.id || 0,
            hari_libur: this.form.hari_libur,
            tanggal_mulai_libur: this.form.id
              ? this.form.tanggal_libur
              : this.form.tanggal_mulai_libur,
            tanggal_akhir_libur: this.form.id
              ? this.form.tanggal_libur
              : this.form.tanggal_akhir_libur,
            unit_utama_id: this.form.unit_utama_id,
            unit_kerja_2_id: this.form.unit_kerja_2_id
          };
          this.saveHoliday(payload);
        }
      }
    },
    // Service
    async getListUnit() {
      try {
        this.loadingUnit = true;
        await DataUtamaService.getUnitKerjaAll({
          platform: this.isPresensi ? "presensi" : "simpeg"
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.units = data;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingUnit = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getListHoliday() {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
      let orderBy = [];
      sortBy.map((d, i) => {
        let sort = sortDesc[i] ? "desc" : "asc";
        orderBy = [...orderBy, d, sort];
        return d;
      });
      try {
        this.loading = true;
        await HolidayService.getList({
          filter: {
            search: this.search,
            unit_utama_id: this.unit,
            unit_kerja_2_id: this.unit2,
            year: this.year
          },
          orderBy,
          pageCurrent: page,
          dataPerPage: itemsPerPage
        })
          .then(response => {
            const { status, data } = response.data;
            const { list, pageTotal, countTotal } = data;
            if (status) {
              let holidays = list;
              holidays.map((data, index) => {
                data.no = itemsPerPage * (page - 1) + (index + 1);
                return data;
              });

              this.holidays = holidays;
              this.pagination.totalItem = parseInt(countTotal);
              this.pagination.pageCount = parseInt(pageTotal);
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async saveHoliday(data) {
      try {
        this.formLoading = true;
        await HolidayService.save(data)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.getListHoliday();
              this.visible = false;
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => {
            this.formLoading = false;
            this.dayDiffTry = this.dayDiffTry + 1;
          });
      } catch (err) {
        console.error(err);
      }
    },
    async getDetailHoliday(id) {
      try {
        this.loading = true;
        await HolidayService.getDetail(id)
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              (this.form.id = data.id_libur),
                (this.form.hari_libur = data.hari_libur),
                (this.form.tanggal_libur = data.tanggal_libur),
                (this.form.unit_utama_id = data.unit_utama_id),
                (this.form.unit_kerja_2_id = data.unit_kerja_2_id);
              this.populateUnit();
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async deleteHoliday(payload) {
      try {
        this.deleteLoading = true;
        await HolidayService.delete(payload)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.getListHoliday();
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.deleteLoading = false));
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    this.year = this.years[1];
    this.getListUnit();
  }
};
</script>
<style lang="scss">
#table-custom.v-data-table {
  color: #717171 !important;
  .v-data-table__wrapper > table > thead {
    background-color: #fdfdfd;
  }
  .v-data-table__wrapper > table > thead > tr > th {
    font-size: 0.775rem;
    font-weight: 400;
  }
  .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 0.775rem;
  }
}
#card-custom .v-input {
  font-size: 0.775rem;
}
</style>
