import BaseInstance from "../base.instance";
import {
  PRESENSI_SCHEDULE_HOLIDAY_LIST,
  PRESENSI_SCHEDULE_HOLIDAY_DETAIL,
  PRESENSI_SCHEDULE_HOLIDAY_SAVE,
  PRESENSI_SCHEDULE_HOLIDAY_DELETE
} from "../constants";

const HolidayService = {
  getList(data) {
    return BaseInstance.post(PRESENSI_SCHEDULE_HOLIDAY_LIST, data);
  },
  getDetail(id) {
    return BaseInstance.fetch(PRESENSI_SCHEDULE_HOLIDAY_DETAIL, id);
  },
  save(data) {
    return BaseInstance.post(PRESENSI_SCHEDULE_HOLIDAY_SAVE, data);
  },
  delete(data) {
    return BaseInstance.post(PRESENSI_SCHEDULE_HOLIDAY_DELETE, data);
  }
};

export default HolidayService;
